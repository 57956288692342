<div>
  <div class="girdle">
    <img src="../../../assets/images/graph-bottom.jpg">
  </div>
  <div class="bottom">
    <img src="../../../assets/images/logo-pgconf.png" style="width: 30%;">
    <div class="contact">
      <span style="color: #333333;font-family: Microsoft YaHei UI;">会议咨询、合作洽谈请联系 : </span>+86 15863186550<span style="color: #8B8A8A;font-family: Microsoft YaHei UI;">（同微信）</span><br>
      <span style="color: #333333;font-family: Microsoft YaHei UI;">大会官方邮箱 : </span>meeting@postgresqlchina.com<br>
      Skype : grant.zhou1<br>
    </div>
    <div style="text-align:center;">
      <img src="../../../assets/images/qrcode.jpg">
      <div>随时关注会议动态</div>
    </div>
  </div>
  <hr>
  <div class="foot">
    <div><a routerLink="/guest">嘉宾</a>/<a routerLink="/agenda">议程</a>/<a routerLink="/sponsor">赞助商</a>/<a routerLink="/organizer">组织者</a>/
      <a *ngIf="!isThemeEnd" (click)="changeUrl('/theme')">提交演讲/</a><a (click)="changeUrl('/signup')">参会报名</a>/
      <a routerLink="/cooperate">合作与招募</a>/<a routerLink="/previous">往届会议</a>/<a href="https://2020.postgresconf.cn/photos" target="_blank">大会图库</a>/
      <a routerLink="/feedback">意见反馈</a>/<a routerLink="/rules">行为守则</a></div>
    <div class="copyright">© 2022 中国开源软件联盟PostgreSQL分会</div>
  </div>

</div>
