import { Component, OnInit, DoCheck } from '@angular/core';
import { StorageService } from '../../service/storage.service';
import { HttpService } from '../../service/http.service';
import { Md5 } from 'ts-md5';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Router, RoutesRecognized, ActivatedRoute, ParamMap,  NavigationStart, NavigationEnd  } from '@angular/router';
import { TimeXService } from 'src/app/service/TimeX.service';
import { filter } from 'rxjs/operators';
import { VersionService } from '../../service/version.service';
@Component({
  selector: 'app-header2',
  templateUrl: './header2.component.html',
  styleUrls: ['./header2.component.css']
})
export class Header2Component implements OnInit, DoCheck {
  userinfo:any;
  name = '';
  isThemeEnd = false;
  allMeetingList=[];
  currentMeeting = {};
  viewMeetingBol = false;
  oldVersionId:any;
  constructor(
    public http: HttpService,
    private router: Router,
    private route: ActivatedRoute,
    private local: StorageService,
    private modal: NzModalService,
    private timex: TimeXService,
    public version: VersionService
    
  ) {
    if (typeof window !== 'undefined') {
      if(local.get('userinfo')){
        this.userinfo = local.get('userinfo');
        this.name = this.userinfo.name;
      }
      // if(local.get('currentMetting')){
      //   local.set("currentMetting", 2)
      // }
    }
    timex.getTimeX();
    this.isThemeEnd = timex.isThemeEnd;
    if(this.userinfo != null) {
      console.log("this.userinfo:", this.userinfo)
      this.getCurrentList()
    }
    const cur = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
    if(cur == 'mydata'|| cur == 'submittedTheme' || cur == 'signupSuccess') {
      this.viewMeetingBol = true
    }else {
      this.viewMeetingBol = false
    }
  }

  ngOnInit() {
    this.router.events.pipe(//监听路由代码
        filter(event => event instanceof NavigationEnd)
      ).subscribe((res: any) => {
      this.onUrl();//事件操作
    });
  }
  ngDoCheck() {
    if(this.version.get('currentMetting') !== this.oldVersionId) {
      if(this.version.get('currentMetting') == 2) {
        this.currentMeeting = this.allMeetingList[0]
      }else {
        this.currentMeeting = this.allMeetingList[1]
      }
      this.oldVersionId = this.version.get('currentMetting')
    }
  }
  onUrl() {
    const cur = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
    if(cur == 'mydata'|| cur == 'submittedTheme' || cur == 'signupSuccess') {
      this.viewMeetingBol = true
    }else {
      this.viewMeetingBol = false
    }
    console.log('变化了', cur)
    // if()
  }
  //退出登录
  logout(){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|logout');
    var api = 'user/user/logout';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'sessionid': this.userinfo.sessionid,
      'user_id':this.userinfo.id
    }
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.local.remove('userinfo');
        this.local.set('currentMetting', 2);
        // this.local.remove('currentConfMessage')
        this.router.navigate(['/']);
      } else {
        this.modal.error({
          nzTitle: "网站提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
    
  }
  // 获取当前metting
  selectCurrentMetting(obj) {
    this.currentMeeting = obj
    // window.location.reload()
    this.version.set('currentMetting', obj.id)
    // if(this.local.get('currentMetting')==null){
    //   this.local.set('currentMetting', obj.id);
    // }
  }
  // 获取当前大会年份列表
   getCurrentList(){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|allmeeting');
    var api = 'user/user/allMeeting';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'user_id':this.userinfo.id
    }
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.allMeetingList = result.data;
        if(this.local.get('currentMetting') == 1) {
          this.currentMeeting = this.allMeetingList[1]
        }else {
          this.currentMeeting = this.allMeetingList[0]
        }
        
        // this.local.set('currentConfMessage',result.data);
      } else {
        this.modal.error({
          nzTitle: "网站提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
    
  }
}
