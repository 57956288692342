<app-header *ngIf="thisUrl!='/'&&thisUrl!='/login'&&thisUrl!='/register'&&thisUrl!='/signup'&&thisUrl!='/signupSuccess'&&thisUrl!='/theme'&&thisUrl!='/submittedTheme'&&
    thisUrl!='/theme-edit'&&thisUrl!='/mydata'&&thisUrl!='/forget'&&thisUrl!='/account'&&thisUrl!='/en'&&thisUrl!='/sponsor_en'&&thisUrl!='/organizer_en'&&
    thisUrl!='/cooperate_en'&&thisUrl!='/agenda_en'&&thisUrl!='/liveEntry_en'&&thisUrl!='/exhibition_en'&&thisUrl!='/previous_en'&&thisUrl!='/feedback_en'&&
    thisUrl!='/collect_en'&&thisUrl!='/rules_en'&&thisUrl!='/signup_en'&&thisUrl!='/signupSuccess_en'&&this.thisUrl!='/login_en'&&this.thisUrl!='/register_en'&&
    this.thisUrl!='/forget_en'&&this.thisUrl!='/mydata_en'&&this.thisUrl!='/account_en'&&this.thisUrl!='/theme_en'&&this.thisUrl!='/submittedTheme_en'&&
    this.thisUrl!='/theme-edit_en'&&thisUrl!='/speech_en'&&thisUrl!='/live_en'">
</app-header>

<app-header2 *ngIf="thisUrl=='/login'||thisUrl=='/register'||thisUrl=='/signup'||thisUrl=='/signupSuccess'||thisUrl=='/theme'||thisUrl=='/submittedTheme'||
thisUrl=='/theme-edit'||thisUrl=='/mydata'||thisUrl=='/forget'||thisUrl=='/account'">
</app-header2>

<app-header_en *ngIf="thisUrl=='/sponsor_en'||thisUrl=='/organizer_en'||thisUrl=='/cooperate_en'||thisUrl=='/agenda_en'||thisUrl=='/liveEntry_en'||
thisUrl=='/exhibition_en'||thisUrl=='/previous_en'||thisUrl=='/feedback_en'||thisUrl=='/collect_en'||thisUrl=='/rules_en'||thisUrl=='/speech_en'||thisUrl=='/live_en'">
</app-header_en>

<app-header2_en *ngIf="thisUrl=='/signup_en'||thisUrl=='/signupSuccess_en'||this.thisUrl=='/login_en'||this.thisUrl=='/register_en'||this.thisUrl=='/forget_en'||
this.thisUrl=='/mydata_en'||this.thisUrl=='/account_en'||this.thisUrl=='/theme_en'||this.thisUrl=='/submittedTheme_en'||this.thisUrl=='/theme-edit_en'">
</app-header2_en>

<router-outlet></router-outlet>

<app-footer *ngIf="thisUrl!='/login'&&thisUrl!='/register'&&thisUrl!='/signup'&&thisUrl!='/signupSuccess'&&thisUrl!='/theme'&&thisUrl!='/submittedTheme'&&
    thisUrl!='/theme-edit'&&thisUrl!='/mydata'&&thisUrl!='/forget'&&thisUrl!='/account'&&thisUrl!='/en'&&thisUrl!='/sponsor_en'&&thisUrl!='/organizer_en'&&
    thisUrl!='/cooperate_en'&&thisUrl!='/agenda_en'&&thisUrl!='/liveEntry_en'&&thisUrl!='/exhibition_en'&&thisUrl!='/previous_en'&&thisUrl!='/feedback_en'&&
    thisUrl!='/collect_en'&&thisUrl!='/rules_en'&&thisUrl!='/signup_en'&&thisUrl!='/signupSuccess_en'&&this.thisUrl!='/login_en'&&this.thisUrl!='/register_en'&&
    this.thisUrl!='/forget_en'&&this.thisUrl!='/mydata_en'&&this.thisUrl!='/account_en'&&this.thisUrl!='/theme_en'&&this.thisUrl!='/submittedTheme_en'&&
    this.thisUrl!='/theme-edit_en'&&thisUrl!='/speech_en'&&thisUrl!='/live_en'">
</app-footer>

<app-footer2 *ngIf="thisUrl=='/login'||thisUrl=='/register'||thisUrl=='/signup'||thisUrl=='/signupSuccess'||thisUrl=='/theme'||thisUrl=='/submittedTheme'||
thisUrl=='/theme-edit'||thisUrl=='/mydata'||thisUrl=='/forget'||thisUrl=='/account'">
</app-footer2>

<app-footer_en *ngIf="thisUrl=='/en'||thisUrl=='/sponsor_en'||thisUrl=='/organizer_en'||thisUrl=='/cooperate_en'||thisUrl=='/agenda_en'||thisUrl=='/liveEntry_en'||
thisUrl=='/exhibition_en'||thisUrl=='/previous_en'||thisUrl=='/feedback_en'||thisUrl=='/collect_en'||thisUrl=='/rules_en'||thisUrl=='/speech_en'||thisUrl=='/live_en'">
</app-footer_en>

<app-footer2_en *ngIf="thisUrl=='/signup_en'||thisUrl=='/signupSuccess_en'||this.thisUrl=='/login_en'||this.thisUrl=='/register_en'||this.thisUrl=='/forget_en'||
this.thisUrl=='/mydata_en'||this.thisUrl=='/account_en'||this.thisUrl=='/theme_en'||this.thisUrl=='/submittedTheme_en'||this.thisUrl=='/theme-edit_en'">
</app-footer2_en>

<nz-back-top [nzVisibilityHeight]="300"></nz-back-top>