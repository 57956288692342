<nz-affix [nzOffsetTop]="0">
  <div class="bg">
      <div class="top">
          <div class="menus">
              <span [class]="{'active':thisUrl=='/en'}" routerLink="/en">Home</span>
              <span [class]="{'active':thisUrl=='/agenda_en'}" (click)="navActive('/agenda_en')">Schedule</span>
              <span [class]="{'active':thisUrl=='/sponsor_en'}" (click)="navActive('/sponsor_en')">Sponsor</span>
              <span [class]="{'active':thisUrl=='/organizer_en'}" (click)="navActive('/organizer_en')">Organizer</span>
              <!-- <span *ngIf="!isThemeEnd" [class]="{'active':thisUrl=='/theme_en'}" (click)="navActive('/theme_en')">Submit speech</span> -->
              <span [class]="{'active':thisUrl=='/cooperate_en'}" (click)="navActive('/cooperate_en')">Cooperate & Recruit</span>
              <span [class]="{'active':thisUrl=='/liveEntry_en'}" (click)="navActive('/liveEntry_en')">Live</span>
              <span [class]="{'active':thisUrl=='/previous_en'}" (click)="navActive('/previous_en')">Previous</span>
              <!-- <span [class]="{'active':thisUrl=='/photo'}" (click)="navActive('/photo')">大会图库</span> -->
              <span routerLink="/">中文</span>

          </div>
          <div class="user" *ngIf="userinfo == null">
              <i class="iconfont icon-yonghutouxiang" style="margin-right: 10px;font-size: 20px;"></i>
              <span><a routerLink="/register_en">Register</a></span>
              <span style="margin: 0 10px;">|</span>
              <span><a routerLink="/login_en">Sign in</a></span>
          </div>
          <div class="user" *ngIf="userinfo != null">
              <span>{{userinfo.name_en}}</span>
              <div nz-dropdown [nzDropdownMenu]="menu" [nzTrigger]="'click'" [nzPlacement]="'bottomRight'" style="cursor: pointer;">
                <img src="../../../assets/images/defaultHead.png" *ngIf="userinfo.img_url==''">
                <img src="{{userinfo.img_url}}" *ngIf="userinfo.img_url!=''">
                <i class="iconfont icon-xiala xiala"></i>
              </div>
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu style="font-size:14px;margin-top: -10px;">
                <li class="addli" nz-menu-item [routerLink]="['/signup_en']">
                  <i class="iconfont icon-canhui1" style="font-size: 18px;"></i>registration
                </li>
                <li class="addli" nz-menu-item [routerLink]="['/mydata_en']">
                  <i class="iconfont icon-baoming" style="font-size: 20px;"></i>complete material
                </li>
                <!-- <li class="addli" nz-menu-item [routerLink]="['/theme_en']" *ngIf="!isThemeEnd">
                  <i class="iconfont icon-jiahao" style="font-size: 18px;"></i>submit speech topic
                </li> -->
                <li class="addli" nz-menu-item [routerLink]="['/submittedTheme_en']">
                  <i class="iconfont icon-tubiao-14" style="font-size: 20px;"></i>my presentation
                </li>
                <li class="addli" nz-menu-item (click)="logout()">
                  <i class="iconfont icon-jianhao" style="font-size: 18px;"></i>sign out
                </li>
              </ul>
              </nz-dropdown-menu>
          </div>
      </div>
  </div>
</nz-affix>