import { Component, OnInit } from '@angular/core';
import { Router, RoutesRecognized, ActivatedRoute } from '@angular/router';
import { StorageService } from '../../service/storage.service';
import { HttpService } from '../../service/http.service';
import { Md5 } from 'ts-md5';
import { NzModalService } from 'ng-zorro-antd/modal';
import {  Subscription } from 'rxjs';
import { TimeXService } from 'src/app/service/TimeX.service';


@Component({
  selector: 'app-header_en',
  templateUrl: './header_en.component.html',
  styleUrls: ['./header_en.component.css']
})
export class Header_enComponent implements OnInit {
  thisUrl = '';    //当前路由
  userinfo:any;
  subscription: Subscription;
  isThemeEnd = false;

  constructor(
    public http: HttpService,
    private router: Router,
    private route: ActivatedRoute,
    private local: StorageService,
    private modal: NzModalService,
    private timex: TimeXService,
  ) { 
    this.thisUrl = this.route.snapshot['_routerState'].url;
    this.subscription = router.events.subscribe(event => {
      if (event instanceof RoutesRecognized) {
        this.thisUrl = event.url;
      }
    });
    if (typeof window !== 'undefined') {
      if(local.get('userinfo')){
        this.userinfo = local.get('userinfo');
      }
    }
    timex.getTimeX();
    this.isThemeEnd = timex.isThemeEnd;
  }

  ngOnInit() {
   
  }


  navActive(url){
    // console.log(url,this.thisUrl)
    if(url!=this.thisUrl){
      if(url=='theme_en'&&this.userinfo==null){
        this.router.navigate(['/login_en'],{queryParams:{type: url}});
      }else{
        this.thisUrl = url;
        this.router.navigate([url])
      }
      
    }
  }

  //退出登录
  logout(){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|logout');
    var api = 'user/user/logout';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'sessionid': this.userinfo.sessionid,
      'user_id':this.userinfo.id
    }
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.local.remove('userinfo');
        this.local.set('currentMetting', 2);
        this.router.navigate(['/en']);
      } else {
        this.modal.error({
          nzTitle: "Tips",
          nzContent: result.message,
          nzOkText: "OK",
          nzOnOk: () => { },
        })
      }
    })
    
  }

}
