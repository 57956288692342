<nz-affix [nzOffsetTop]="0">
    <div class="bg">
        <div class="top">
            <div class="menus">
                <span [class]="{'active':thisUrl=='/'}" routerLink="/">首页</span>
                <span [class]="{'active':thisUrl=='/guest'}" (click)="navActive('/guest')">嘉宾</span>
                <span [class]="{'active':thisUrl=='/agenda'}" (click)="navActive('/agenda')">议程</span>
                <span [class]="{'active':thisUrl=='/sponsor'}" (click)="navActive('/sponsor')">赞助商</span>
                <!-- <span [class]="{'active':thisUrl=='/organizer'}" (click)="navActive('/organizer')">组织者</span> -->
                <!-- <span *ngIf="!isThemeEnd" [class]="{'active':thisUrl=='/theme'}" (click)="navActive('/theme')">提交演讲</span>  -->
                <span [class]="{'active':thisUrl=='/cooperate'}" (click)="navActive('/cooperate')">合作与招募</span>
                <span [class]="{'active':thisUrl=='/liveEntry'}" (click)="toHref('https://marketing.csdn.net/p/6c7d485dbe5ea7de8e2b0a1f0f967573')">直播入口</span>
                <span [class]="{'active':thisUrl=='/accommodation'}" (click)="navActive('/accommodation')">交通住宿</span>
                <span [class]="{'active':thisUrl=='/previous'}" (click)="navActive('/previous')">往届</span>
                <!-- <span><a href="https://2020.postgresconf.cn/photos" target="_blank">大会图库</a></span> -->
                <!-- <span routerLink="/en">EN</span> -->

            </div>
            <div class="user" *ngIf="userinfo == null">
                <button  class="b-btn-orange submit-btn" (click)="isVisibleMiddle=true" style="line-height: 30px;height: 40px;margin-right: 20px;padding: 0 20px;">参会报名<i class="iconfont icon-jiantou_xiangyou" style="font-size: 18px;margin: 10px;"></i></button>
                <!-- <i class="iconfont icon-yonghutouxiang" style="margin-right: 10px;font-size: 20px;"></i> -->
                <a routerLink="/login"><i class="iconfont icon-yonghutouxiang person-icon"></i></a>
                <!-- <span><a routerLink="/register">注册</a></span>
                <span style="margin: 0 10px;">|</span>
                <span><a routerLink="/login">登录</a></span> -->
            </div>
            <div class="user" *ngIf="userinfo != null">
                <button  class="b-btn-orange submit-btn" (click)="isVisibleMiddle=true" style="line-height: 30px;height: 40px;margin-right: 20px;padding: 0 20px;">参会报名<i class="iconfont icon-jiantou_xiangyou" style="font-size: 18px;margin: 10px;"></i></button>
                <span class="user-name-view">{{userinfo.name}}</span>
                <div  nz-dropdown [nzDropdownMenu]="menu" [nzTrigger]="'click'" [nzPlacement]="'bottomRight'" style="cursor: pointer;">
                    <img src="../../../assets/images/defaultHead.png" *ngIf="userinfo.img_url==''">
                    <img src="{{userinfo.img_url}}" *ngIf="userinfo.img_url!=''">
                    <i class="iconfont icon-xiala xiala"></i>
                </div>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                    <ul nz-menu style="font-size:14px;margin-top: -15px;">
                        <li class="addli" nz-menu-item [routerLink]="['/signup']">
                            <i class="iconfont icon-canhui1" style="font-size: 18px;"></i>参会报名
                        </li>
                        <li class="addli" nz-menu-item [routerLink]="['/mydata']">
                            <i class="iconfont icon-baoming" style="font-size: 20px;"></i>完善个人资料
                        </li>
                        <li class="addli" nz-menu-item [routerLink]="['/theme']" *ngIf="!isThemeEnd">
                            <i class="iconfont icon-jiahao" style="font-size: 18px;"></i>提交演讲话题
                        </li>
                        <li class="addli" nz-menu-item [routerLink]="['/submittedTheme']">
                            <i class="iconfont icon-tubiao-14" style="font-size: 20px;"></i>我提交的演讲
                        </li>
                        <li class="addli" nz-menu-item (click)="logout()">
                            <i class="iconfont icon-jianhao" style="font-size: 18px;"></i>退出登录
                        </li>
                    </ul>
                </nz-dropdown-menu>
            </div>
        </div>
    </div>
</nz-affix>
<nz-modal
[(nzVisible)]="isVisibleMiddle"
nzCentered
(nzOnCancel)="handleCancelMiddle()"
(nzOnOk)="handleOkMiddle()"
[nzTitle]="modalTitle"
[nzContent]="modalContent"
[nzFooter]="modalFooter"
>
<ng-template #modalTitle id="sign-modal">
  <img src="../../../assets/images/neiye/graph-signup.png" alt="" style="width: 100%;">
</ng-template>
      <ng-template #modalContent>
        <div class="big-title" style="font-size: 30px;font-family: Helvetica Neue LT Pro;">PostgresConf.CN <span style="font-family: Akira Outline;-webkit-text-stroke: 1px #000;">2022</span></div>
        <div>请选择参会方式</div>
        <div style="text-align: center">
          <button class="b-btn-orange submit-btn" (click)="goUrl('/signup')" style="margin-right: 20px;">线下参会</button>
          <a  (click)="toHref('https://marketing.csdn.net/p/6c7d485dbe5ea7de8e2b0a1f0f967573')" target="_blank"><button class="b-btn-blue submit-btn" (click)="isVisibleMiddle=true">线上参会</button></a>
        </div>
      </ng-template>

      <ng-template #modalFooter></ng-template>
</nz-modal>