import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

constructor() { }

 /***********************************************************************
   * 封装localStorage的三个方法，这样就可以直接存取对象类型
   * @param key 
   * @param value 
   */
  set(key, value) {
    window.localStorage.setItem(key, JSON.stringify(value));  /*对象转换成字符串*/
  }
  get(key) {
    return JSON.parse(window.localStorage.getItem(key));   /*字符串转换成对象*/
  }
  remove(key) {
    window.localStorage.removeItem(key);
  }

}
