import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8' })
  };

constructor(

  public http: HttpClient,
  public config: ConfigService,
  private router: Router,
) { }

    //post
    post(apiUrl, json, callback){
      this.http.post(apiUrl, json, this.httpOptions).subscribe((response) => {
        callback(response);
      });
    }

  //get请求数据
  doGet(apiUrl, json, callback) {
    // var body = JSON.stringify(data);
    let api = this.config.apiUrl + apiUrl + '?' + json;
    this.http.get(api).subscribe((response) => {
      // this.checkToken(response);
      callback(response);
    });
  }

   //post提交数据
   doPost(apiUrl, json, callback){
    var api = this.config.apiUrl + apiUrl;
    this.http.post(api, json, this.httpOptions).subscribe((response) => {
      // this.checkToken(response);
      callback(response);
    });
  }





}
