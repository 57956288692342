
import { Injectable } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Md5 } from "ts-md5";
import { HttpService } from './http.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

constructor(
  private http: HttpService,
  private local: StorageService,
  private modal: NzModalService,
) { 

}

getProfile(id,sessionid,meeting_id){
  let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getprofile');
  var api = 'user/user/getProfile';
  var body = {
    'timestamp': new Date().getTime().toString().substr(0, 10),
    'signature': signature,
    'sessionid': sessionid,
    'user_id': id,
    'language_type':2,
    'meeting_id': meeting_id
  }
  // console.log("body", body);
  this.http.doPost(api, body, (result) => {
    // console.log(result);
    if (result.code == "200") {
      this.local.set('userinfo',result.data);
    } else {
      this.modal.error({
        nzTitle: "Tips",
        nzContent: result.message,
        nzOkText: "OK",
        nzOnOk: () => { },
      })
    }
  })
}
getCurrentUser(id,sessionid,meeting_id, callback){
  let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|getprofile');
  var api = 'user/user/getProfile';
  var body = {
    'timestamp': new Date().getTime().toString().substr(0, 10),
    'signature': signature,
    'sessionid': sessionid,
    'user_id': id,
    'language_type':2,
    'meeting_id': meeting_id
  }
  // console.log("body", body);
  this.http.doPost(api, body, (result) => {
    // console.log(result);
    if (result.code == "200") {
      // this.local.set('userinfo',result.data);
      callback(result.data)
    } else {
      this.modal.error({
        nzTitle: "Tips",
        nzContent: result.message,
        nzOkText: "OK",
        nzOnOk: () => { },
      })
    }
  })
}

}
