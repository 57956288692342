<div>
  <div class="girdle">
    <!-- <img src="../../../assets/images/bottom-graph.jpg"> -->
  </div>
  <div class="bottom">
    © 2022 中国开源软件联盟PostgreSQL分会
    <!-- <div style="line-height: 34px;">鲁ICP备09010331号-9</div> -->
  </div>

</div>

