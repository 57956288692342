import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../service/http.service';
import { Md5 } from 'ts-md5';
import { Router, RoutesRecognized, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { StorageService } from '../../service/storage.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { TimeXService } from '../../service/TimeX.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
})
export class MainComponent implements OnInit {

  scroll = 0;
  timeDay = 0;
  timeDay1 = 0;
  show = false;
  userinfo:any;
  meetingList: any = [];
  isThemeEnd = false;  // 提交演讲是否截止
  isEnd = false; // 提交ppt是否截止
  isVisibleMiddle = false; // 报名弹框
  isDelayVisible = false;
  constructor(
    public http: HttpService,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private local: StorageService,
    private modal: NzModalService,
    private timex: TimeXService,
  ) {
    titleService.setTitle('PostgresConf.CN 2022');
    if (typeof window !== 'undefined') {
      if(local.get('userinfo')){
        this.userinfo = local.get('userinfo');
      }
      window.scrollTo(0, 0);
      window.onscroll = () => {
        this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
        if (this.scroll) {
          // $('.header').attr('style', 'background:rgb(0, 0, 0,0.5);');
        }
        if (this.scroll == 0) {
          // $('.header').removeAttr('style');
        }
      };
    }
    timex.getTimeX('2023/1/10 23:59:59','2023/1/31 23:59:59');
    this.timeDay = timex.timeDay;
    this.timeDay1 = timex.timeDay1;
    this.isThemeEnd = timex.isThemeEnd;
    this.isEnd = timex.isEnd;
   
    this.getAgenda();
  }

  ngOnInit() {}
  
  toHref(url) {
    // this.modal.success({
    //   nzTitle: "网站提示",
    //   nzContent: "感谢您的关注，敬请期待！",
    //   nzOkText: "关闭",
    //   nzOnOk: () => { },
    // })
    // return
    window.open(url)
  }
  goUrl(url){
    // this.router.navigate([url]);
    if (typeof window !== 'undefined') {
      if(this.local.get('userinfo')==null){
        this.router.navigate(['/login'],{queryParams:{type: url}});
      }else{
        if(url=='/signup') {
          this.local.set('currentMetting', 2)
          this.router.navigate([url],{queryParams:{type: 2}});
        }else {
          this.router.navigate([url]);
        }
        
      }
    }
  }
  handleOkMiddle() {
    this.isVisibleMiddle = false;
  }

  handleCancelMiddle() {
    this.isVisibleMiddle = false;
  }
  handleDelayOkMiddle() {
    this.isDelayVisible = false;
  }

  handleDelayCancelMiddle() {
    this.isDelayVisible = false;
  }
  //获取日程
  getAgenda(){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|meetinglist');
    var api = 'user/user/meetingList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'meeting_id': 2,
      'language_type':1,
    }
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.meetingList = result.data;
      } else {
        this.modal.error({
          nzTitle: "网站提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
  }


  //退出登录
  logout(){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|logout');
    var api = 'user/user/logout';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'sessionid': this.userinfo.sessionid,
      'user_id':this.userinfo.id,
      'language_type':1,
    }
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.local.remove('userinfo');
        this.local.set('currentMetting', 2);
        window.location.replace('');
      } else {
        this.modal.error({
          nzTitle: "网站提示",
          nzContent: result.message,
          nzOkText: "关闭",
          nzOnOk: () => { },
        })
      }
    })
    
  }
}
