import { Component } from '@angular/core';
import { HttpService } from './service/http.service';
import { Md5 } from "ts-md5";
import { Router, RoutesRecognized, ActivatedRoute } from '@angular/router';
import { StorageService } from './service/storage.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ProfileService } from './service/profile.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  thisUrl = '/';
  userinfo:any;

  constructor(
    public http: HttpService,
    private router: Router,
    private route: ActivatedRoute,
    private local: StorageService,
    private modal: NzModalService,
    private profile: ProfileService,
  ) {
    router.events.subscribe(event => {
      if (event instanceof RoutesRecognized) {
      var thisUrl = event.url.split('?')[0].split('/')[1];
      this.thisUrl = '/'+ thisUrl;
      }
    });
    if (typeof window !== 'undefined') {
      if(local.get('userinfo')){
        this.userinfo = local.get('userinfo');
        this.checkSession();
        this.profile.getProfile(this.userinfo.id,this.userinfo.sessionid, 2);
      }
    }
  }



    /**
   * 判断登录是否过期
   */
  checkSession() {
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|checksession');
    var api = 'user/user/checkSession';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'sessionid': this.userinfo.sessionid,
      'user_id': this.userinfo.id,
      'language_type':2,
    }
    // console.log("body", body);
    this.http.doPost(api, body, (result) => {
      //  console.log(result);
      if (result.code != "200") {
        this.local.remove('userinfo');
        this.local.set('currentMetting', 2);
        window.location.reload();
      } 
    })
  }
}
