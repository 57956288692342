import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  public apiUrl = "https://admin.postgresconf.cn/";
  constructor() { }

}
