import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../service/http.service';
import { Md5 } from 'ts-md5';
import { Router, RoutesRecognized, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { StorageService } from '../../service/storage.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import * as $ from 'jquery';
import { TimeXService } from 'src/app/service/TimeX.service';

@Component({
  selector: 'app-main_en',
  templateUrl: './main_en.component.html',
  styleUrls: ['./main_en.component.css']
})
export class Main_enComponent implements OnInit {

  scroll = 0;
  timeDay = 0;
  timeDay1 = 0;
  show = false;
  userinfo:any;
  meetingList: any = [];
  isThemeEnd = false;
  isEnd = false;

  constructor(
    public http: HttpService,
    private router: Router,
    private route: ActivatedRoute,
    private titleService: Title,
    private local: StorageService,
    private modal: NzModalService,
    private timex: TimeXService,
  ) {
    titleService.setTitle('Home-PostgresConf.CN & PGConf.Asia 2021');
    if (typeof window !== 'undefined') {
      if(local.get('userinfo')){
        this.userinfo = local.get('userinfo');
      }
      window.scrollTo(0, 0);
      window.onscroll = () => {
        this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
        if (this.scroll) {
          $('.header').attr('style', 'background:rgb(0, 0, 0,0.5);');
        }
        if (this.scroll == 0) {
          $('.header').removeAttr('style');
        }
      };
    }
    timex.getTimeX();
    this.timeDay = timex.timeDay;
    this.timeDay1 = timex.timeDay1;
    this.isThemeEnd = timex.isThemeEnd;
    this.isEnd = timex.isEnd;
    this.getAgenda();
  }

  ngOnInit() {}

  goUrl(url){
    if (typeof window !== 'undefined') {
      if(this.local.get('userinfo')==null){
        this.router.navigate(['/login_en'],{queryParams:{type: url}});
      }else{
        this.router.navigate([url]);
      }
    }
  }

  //获取日程
  getAgenda(){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|meetinglist');
    var api = 'user/user/meetingList';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'meeting_id': 2,
      'language_type':2,
    }
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.meetingList = result.data;
      } else {
        this.modal.error({
          nzTitle: "Tips",
          nzContent: result.message,
          nzOkText: "OK",
          nzOnOk: () => { },
        })
      }
    })
  }


  //退出登录
  logout(){
    let signature = Md5.hashStr('CYYbQyB7FdIS8xuBEwVwbBDMQKOZPMXK|' + new Date().getTime().toString().substr(0, 10) + '|logout');
    var api = 'user/user/logout';
    var body = {
      'timestamp': new Date().getTime().toString().substr(0, 10),
      'signature': signature,
      'sessionid': this.userinfo.sessionid,
      'user_id':this.userinfo.id,
      'language_type':2,
    }
    this.http.doPost(api, body, (result) => {
      // console.log(result);
      if (result.code == "200") {
        this.local.remove('userinfo');
        window.location.replace('/en');
      } else {
        this.modal.error({
          nzTitle: "Tips",
          nzContent: result.message,
          nzOkText: "OK",
          nzOnOk: () => { },
        })
      }
    })
    
  }
}

