<div>
  <div class="girdle">
    <img src="../../../assets/images/bottom-graph.jpg">
  </div>
  <div class="bottom">
    © 2021 China PostgreSQL Association
    <div style="line-height: 34px;">鲁ICP备09010331号-9</div>
  </div>
</div>

